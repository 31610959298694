import request from '@/until/request.js'
const prefixName = '/gm/webLedgerDispose'
// 退货单
export function getRecycleLedgerList(data){
  return request({
    url: prefixName,
    method: 'get',
    params: { ...data }
  })
}
// 检查退单号id
export function getRecycleLedgerCode(){
  return request({
    url: prefixName + '/getCode',
    method: 'get'
  })
}
// 检查退单号id
export function getRecycleLedgerDetails(id){
  return request({
    url: prefixName + '/details',
    method: 'get',
    params: { id }
  })
}
// 退货单仓库商品列表
export function addRecycleLedger(data){
  return request({
    url: prefixName,
    method: 'post',
    data
  })
}
// 退货单仓库商品列表
export function editRecycleLedger(data){
  return request({
    url: prefixName,
    method: 'put',
    data
  })
}
// 删除零售单
export function delRecycleLedger(id) {
  return request({
      url: prefixName + '/' + id,
      method: 'delete'
  })
}


export function getRecycleList(data){
  return request({
    url: prefixName+'/recycle',
    method: 'get',
    params: { ...data }
  })
}

// 退货单仓库商品列表
export function addRecycle(data){
  return request({
    url: prefixName+'/recycle',
    method: 'post',
    data
  })
}
// 退货单仓库商品列表
export function editRecycle(data){
  return request({
    url: prefixName+'/recycle',
    method: 'put',
    data
  })
}
// 删除零售单
export function delRecycle(id) {
  return request({
      url: prefixName + '/recycle/' + id,
      method: 'delete'
  })
}

export function RecycleLedgerExport(id){
  return request({
    url: prefixName+'/export',
    method: 'get',
    responseType: 'blob',
    params: { id }
  })
}