<template>
  <div>
    <mia-navback @back="back" :isShowSave="!isView" @submit="submitForm" />
    <mia-Card title="基础信息">
      <div class="header" slot="content">
        <el-form :inline="true">
          <el-form-item label="台账编号:">
            {{ recycleLedger.no }}
          </el-form-item>
          <el-form-item v-if="recycleLedger.updateDate" label="修改时间:">
            {{ recycleLedger.updateDate }}
          </el-form-item>
          <el-form-item v-if="recycleLedger.updateUserName" label="最后修改人:">
            {{ recycleLedger.updateUserName }}
          </el-form-item>
        </el-form>
      </div>
    </mia-Card>
    <mia-Card title="选择回收农药废弃物">
      <div slot="content">
        <div>
          <el-button type="primary" @click="openRecycle"
            >选择回收农药废弃物</el-button
          >
        </div>
        <el-table
          :data="selectedRecyles"
          style="width: 100%"
          show-summary
          :summary-method="getSummaries"
        >
          <el-table-column type="index" prop="index" label="序号">
          </el-table-column>
          <el-table-column prop="name" label="回收农药名称" width="180">
          </el-table-column>
          <el-table-column prop="spec" label="单位/规格" width="180">
          </el-table-column>
          <el-table-column prop="recycleDate" label="回收日期">
          </el-table-column>
          <el-table-column prop="recycleNum" label="回收数量">
          </el-table-column>
          <el-table-column prop="type" label="农药废弃物类别">
          </el-table-column>
          <el-table-column prop="source" label="农药废弃物来源">
          </el-table-column>
          <el-table-column prop="saveDate" label="暂存仓库日期">
          </el-table-column>
        </el-table>
      </div>
    </mia-Card>
    <mia-Card title="处置信息">
      <div slot="content">
        <el-row style="height: 100px; line-height: 100px">
          <el-col :span="8">
            <span>处置时间：</span>
            <el-date-picker
              v-model="recycleLedger.disposeDate"
              :disabled="isView"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="8">
            <span>处置去向：</span>
            <el-select
              v-model="recycleLedger.disposeWhither"
              filterable
              clearable
              placeholder="请选择去向"
            >
              <el-option
                v-for="(item, Index) in disposeWhithers"
                :key="Index"
                :label="item.name"
                :value="item.name"
              />
            </el-select>
          </el-col>
          <el-col :span="8" style="display: flex">
            <span>处置人签名：</span>
            <div v-if="isView" style="display: inline-block">
              <el-image
                style="width: 100px; height: 100px"
                :src="getImg(recycleLedger.sign)"
                fit="contain"
              ></el-image>
            </div>
            <div v-else style="display: inline-block" @click="openSign">
              <div v-if="!recycleLedger.sign" class="sign">点击签名</div>
              <el-image
                v-else
                style="width: 100px; height: 100px"
                fit="contain"
                :src="getImg(recycleLedger.sign)"
              ></el-image>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <span>农药废弃物交收清单：</span>
            <UploadImg
              style="margin-right: 30px"
              :limit-count="4"
              :disabled="isView"
              :image-list="imageList"
              @imgUploadSuc="imgUploadSuc"
              @imageRemove="imageRemove"
            />
          </el-col>
        </el-row>
      </div>
    </mia-Card>
    <Sign
      :visible="signatureVisible"
      @finsih="signFisish"
      @close="signatureVisible = false"
    ></Sign>
    <!-- 回收废弃物 -->
    <miaDialog ref="recycleDialog" v-if="recycleVisible">
      <div slot="content" style="max-height: 500px">
        <SimpleTable
          :data="recycleData"
          :tableFields="recycleTableFields"
          :currentPage="1"
          :pageSize="500"
          :total="0"
          :showPagination="false"
          @selectChange="selectChange"
        >
        </SimpleTable>
        <div style="width: 100%; text-align: center; margin-top: 50px">
          <el-button type="primary" @click="confirm">确认</el-button>
          <el-button @click="close">取消</el-button>
        </div>
      </div>
    </miaDialog>
  </div>
</template>
<script>
import Sign from "@/components/Sign";
import { uploadFile } from "@/api/common";
import { imgUrl } from "../../../../settting";
import UploadImg from "@/components/uploadImg";
import {
  getRecycleLedgerCode,
  getRecycleLedgerDetails,
  addRecycleLedger,
  editRecycleLedger,
  getRecycleList,
} from "@/api/recycleLedger";
import moment from "moment";
export default {
  components: { Sign, UploadImg },
  data() {
    return {
      recycleLedger: {},
      checkPlace: [],
      isView: false,
      signatureVisible: false,
      selectedRecyles: [],
      disposeWhithers: [
        {
          id: "交供货方",
          name: "交供货方",
        },
        {
          id: "交专业机构",
          name: "交专业机构",
        },
      ],
      imageList: [],
      recycleData: [],
      recycleTableFields: [
        { label: "", type: "selection" },
        { prop: "name", label: "回收农药名称", type: "default" },
        { prop: "spec", label: "单位/规格", type: "default" },
        { prop: "recycleDate", label: "回收日期", type: "default" },
        { prop: "recycleNum", label: "回收数量", type: "number" },
        { prop: "type", label: "农药废弃物类别", type: "number" },
        { prop: "source", label: "农药废弃物来源", type: "number" },
        { prop: "saveDate", label: "暂存仓库日期", type: "number" },
      ],
      recycleVisible: false, // 废弃物弹窗
      chekedRecycles: [],
    };
  },
  methods: {
    init(id, view) {
      if (id) {
        getRecycleLedgerDetails(id).then((res) => {
          if (res.code === 1) {
            this.recycleLedger = res.data;
            if (res.data.prove) {
              this.imageList = res.data.prove.split(",").map((v) => {
                return {
                  src: imgUrl + v,
                  value: v,
                };
              });
            }
            this.isView = view;
          }
        });
      } else {
        getRecycleLedgerCode().then((res) => {
          if (res.code == 1) {
            this.recycleLedger = {
              ...this.recycleLedger,
              no: res.data,
              checkDate: moment().format("YYYY-MM-DD"),
            };
          }
        });
      }
    },
    getImg(url) {
      return imgUrl + url;
    },
    valChange(evt, name, val) {
      if (!evt) {
        this.safeItems = this.safeItems.map((v) => {
          if (name === v.name) {
            v.value = val;
          }
          return v;
        });
      } else {
        if (val === "1") {
          this.safeItems = this.safeItems.map((v) => {
            if (name === v.name) {
              v.method = "0";
            }
            return v;
          });
        }
      }
    },
    val1Change(evt, name, val) {
      if (!evt) {
        this.safeItems = this.safeItems.map((v) => {
          if (name === v.name) {
            v.method = val;
          }
          return v;
        });
      }
    },
    back() {
      this.$emit("back");
    },
    checkPlaceChange(val) {
      this.checkPlace = val;
    },
    submitForm() {
      if (!this.recycleLedger.disposeDate) {
        return this.$message.error("请选择处置时间");
      }
      if (!this.recycleLedger.disposeWhither) {
        return this.$message.error("请选择处置去向");
      }
      if (!this.recycleLedger.sign) {
        return this.$message.error("请检查人签字");
      }
      if (this.selectedRecyles.length == 0) {
        return this.$message.error("请选择要处置的废弃物");
      }
      this.recycleLedger.list = this.selectedRecyles;
      this.recycleLedger.prove = this.imageList.map((v) => v.value).join(",");
      if (this.recycleLedger.id) {
        editRecycleLedger(this.recycleLedger).then((res) => {
          if (res.code === 1) {
            this.$message.success("操作成功");
            this.back();
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        addRecycleLedger(this.recycleLedger).then((res) => {
          if (res.code === 1) {
            this.$message.success("操作成功");
            this.back();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    openSign() {
      this.signatureVisible = true;
    },
    signFisish(val) {
      const imgs = this.base64ImgtoFile(val);
      const formData = new FormData();
      formData.append("file", imgs);
      uploadFile(formData).then((res) => {
        if (res.code === 1) {
          this.recycleLedger = { ...this.recycleLedger, sign: res.url };
        }
      });
      this.signatureVisible = false;
    },
    base64ImgtoFile(dataurl, filename = "file") {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let suffix = mime.split("/")[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime,
      });
    },
    imgUploadSuc(file, list) {
      const temps = [];
      list.forEach((v) => {
        const picUrl = v.response ? v.response.url : v.value;
        temps.push({
          src: imgUrl + picUrl,
          value: picUrl,
        });
      });
      this.imageList = temps;
    },
    imageRemove(field) {},
    selectChange(data) {
      this.chekedRecycles = data;
    },
    openRecycle() {
      // 回收废弃列表
      this.recycleVisible = true;

      this.$nextTick(() => {
        this.$refs.recycleDialog.init("回收废弃物列表");
      });
      this.getRecycle();
    },
    getRecycle() {
      const params = { isBind: 2 };

      getRecycleList(params).then((res) => {
        if (res.code === 1) {
          // const { records, current, size, total } = res.data;
          this.recycleData = res.data;
          // this.recycleTotal = total;
          // this.searcForm.pageNum = current;
          // // 当没有数据
          // if (records.length === 0) {
          //   // 判断是否为第一页
          //   if (current === 1) {
          //     this.recycleSearch.pageNum = 1;
          //     return;
          //   } else {
          //     // 不是回退再重新搜索
          //     this.recycleSearch.pageNum = current - 1;
          //     this.getRecycle();
          //   }
          // }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    confirm() {
      this.selectedRecyles = this.chekedRecycles;
      this.close();
    },
    close() {
      this.$refs.recycleDialog.close();
    },
    getSummaries(param) {
      const { columns, data } = param;
      let sum = 0;
      const values = data.map((item) => Number(item["recycleNum"]));
      if (!values.every((value) => isNaN(value))) {
        sum = values.reduce((prev, curr) => {
          const value = Number(curr);
          if (!isNaN(value)) {
            return prev + curr;
          } else {
            return prev;
          }
        }, 0);
      } else {
        sum = "";
      }
      return ["合计", "", "", "", sum, "", "", ""];
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  width: 80%;
  margin: 0 auto;
  padding: 20px 50px;
  /deep/ .el-form-item {
    width: 32%;
    &:nth-child(1) {
      text-align: left;
    }
    &:nth-child(2) {
      text-align: center;
    }
    &:nth-child(3) {
      text-align: right;
    }
  }
}
/deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #409eff;
  color: #409eff;
}
.content {
  text-align: center;
  width: 80%;
  margin: 0 auto;
}
table {
  width: 100%;
}
.inline-table {
  border: 0px;
}
table,
td {
  border-collapse: collapse;
  border: 1px solid #999;
}
td {
  padding: 15px;
  font-size: 14px;
}
.sign {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  cursor: pointer;
}
</style>
